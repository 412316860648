import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'
import i18n from '@/libs/i18n'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCustomersList() {
  const toast = useToast()
  const refCustomerListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'customer', label: 'Kunde', sortable: true, sortKey: "vorname" },
    // { key: 'emailadresse', sortable: true },
    { key: 'telefonnummer', sortable: false },
    { key: 'address', label: 'Adresse', sortable: false },
    { key: 'geburtsdatum', sortable: true, formatter: val => moment(String(val)).format('DD.MM.YYYY') },
    { key: 'registrationsdatum', sortable: true, formatter: val => moment(String(val)).format('DD.MM.YYYY') },
    //{ key: 'updated_at', label: i18n.t("Updated At"), sortable: true, formatter: val => moment(String(val)).format('DD.MM.YYYY hh:mm:ss') },
    { key: 'actions', label: i18n.t("Actions") },
  ]

  const perPage = ref(10)
  const totalCustomers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('vorname')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerListTable.value ? refCustomerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomers.value,
    }
  })

  const refetchData = () => {
    refCustomerListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchCustomers = (ctx, callback) => {
    store
      .dispatch('app-customer/fetchCustomers', {
        search_terms: searchQuery.value,
        size: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const responseData = response.data

        callback(responseData.data)
        totalCustomers.value = responseData.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching customers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchCustomers,
    tableColumns,
    perPage,
    currentPage,
    totalCustomers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerListTable,
    refetchData,
  }
}