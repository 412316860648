<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

            <b-button variant="primary" @click="onExport()">
              {{ $t("export") }}
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search kunde...')"
              />

              <b-button variant="primary" @click="onNewKunde">
                <span class="text-nowrap">Neuer Kunde</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCustomerListTable"
        class="position-relative"
        :items="fetchCustomers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(customer)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'customers-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.vorname }} {{ data.item.nachname }}
            </b-link>
            <small class="text-muted">{{ data.item.emailadresse }}</small>
          </b-media>
        </template>

        <template #cell(address)="data">
          {{ data.item.adresse.strassename }}
          {{ data.item.adresse.strassenummer }},
          {{ data.item.adresse.postleitzahl }} {{ data.item.adresse.ort }},
          {{ data.item.adresse.kanton }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'customers-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t("Details") }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'customers-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("Edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showMsgDeleteConfirm(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t("Delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >{{ $t("Showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCustomers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import i18n from "@/libs/i18n";
import useCustomersList from "./useCustomersList";
import customerStoreModule from "../customerStoreModule";
import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationContent,
  },
  setup() {
    const CUSTOMER_STORE_MODULE_NAME = "app-customer";

    // Register module
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME))
      store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule);

    // Unregister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME))
        store.unregisterModule(CUSTOMER_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const {
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,
    } = useCustomersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,
    };
  },
  methods: {
    onExport() {
      var vm = this;

      axios({
        url: `/kunde/export`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        const [, filename]=response.headers["content-disposition"].split('filename=');
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    onNewKunde() {
      const userData = getUserData();

      let routeData = this.$router.resolve({
        name: "customers-add",
        params: { mitarbeiter_id: userData.extras.mitarbeiter_id },
      });

      const windowFeatures = "location=yes,scrollbars=yes,status=yes";
      window.open(routeData.href, "kunde", windowFeatures);
    },
    showMsgDeleteConfirm(customerId) {
      var vm = this;

      this.$bvModal
        .msgBoxConfirm(
          i18n.t("Are you sure you want to delete this customer?"),
          {
            title: i18n.t("Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: i18n.t("Yes"),
            cancelTitle: i18n.t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            store
              .dispatch("app-customer/removeCustomer", { id: customerId })
              .then(() => {
                vm.refetchData();

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t("Deleted"),
                    icon: "TrashIcon",
                    variant: "success",
                  },
                });
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
